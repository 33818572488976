(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/helper-formatdate/assets/javascripts/format-date.js') >= 0) return;  svs.modules.push('/components/lb-utils/helper-formatdate/assets/javascripts/format-date.js');

'use strict';

const {
  isToday,
  isTomorrow,
  format,
  differenceInDays,
  isYesterday,
  subMinutes
} = typeof exports === 'object' ? require('date-fns') : dateFns;
const resolveTimeZone = date => {
  if (svs.lbUtils && svs.lbUtils.helperFormatDate && svs.lbUtils.helperFormatDate.data) {
    const serverOffset = svs.lbUtils.helperFormatDate.data.timeZoneOffset;
    const localOffset = new Date().getTimezoneOffset();
    const offsetDifference = serverOffset - localOffset;
    return subMinutes(date, offsetDifference);
  }
  return date;
};
const getFormattedTime = date => {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  if (isNaN(date)) {
    return '';
  }
  return format(date, 'HH:mm', {
    locale: dateFns.locales.sv
  });
};
const getFormattedDate = function (date, includeDay) {
  let capitalizeFirstChar = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  let useShortDate = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  if (isNaN(date)) {
    return '';
  }
  let day = '';
  if (includeDay) {
    if (isToday(date)) {
      day += 'idag';
    } else if (isTomorrow(date)) {
      day += 'imorgon';
    } else if (isYesterday(date)) {
      day += 'igår';
    } else if (dateFns.isPast(date) || Math.abs(differenceInDays(date, new Date())) > 6) {
      day += format(date, useShortDate ? 'd/M' : 'd MMMM', {
        locale: dateFns.locales.sv
      });
    } else {
      day += format(date, 'EEEE', {
        locale: dateFns.locales.sv
      });
    }
  } else {
    day += format(date, useShortDate ? 'd/M' : 'd MMMM', {
      locale: dateFns.locales.sv
    });
  }
  if (capitalizeFirstChar) {
    day = day.charAt(0).toUpperCase() + day.slice(1);
  }
  return day;
};
const formatDate = function (date) {
  let includeTime = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  let includeDay = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  let capitalizeFirstChar = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  let useShortDate = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  if (isNaN(date)) {
    return '';
  }
  let ret = '';
  ret = getFormattedDate(date, includeDay, capitalizeFirstChar, useShortDate);
  if (includeTime) {
    ret += " ".concat(getFormattedTime(date));
  }
  if (capitalizeFirstChar) {
    ret = ret.charAt(0).toUpperCase() + ret.slice(1);
  }
  return ret.replace(/\s/g, '\xA0');
};
if (typeof exports === 'object') {
  module.exports = {
    formatDate,
    getFormattedDate,
    getFormattedTime,
    resolveTimeZone
  };
} else {
  setGlobal('svs.components.lbUtils.helpers.formatDate', {
    getFormattedDate,
    getFormattedTime,
    formatDate,
    resolveTimeZone
  });
}

 })(window);